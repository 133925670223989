


/* ----------- NEW: ADVANCED SIZING TOOL --------------- */

function HandleAdvancedSizingTool(objButton) {
    var buttonId = objButton.id;
    var currentStep = $("#advancedsizingtool form input#currentstep").val();
    var intCurrentStep = parseInt(currentStep);
    var bodylengthunit = $("input#bodylengthunit").val();
    var baseApiUrl = window.IsInTestMode() ? "https://api2-test.giant-bicycles.com" : "https://api2.giant-bicycles.com";
    var addUp = true;
    var hasMissingValue = false;

    if (buttonId == "reset")
    {
        // back to square one
        SetSizingToolStatusValues(intCurrentStep, 1);
        // reset
        $("p#message").text("");
        $("p.intro").show();
    }
    else if (buttonId == "back")
    {
        // back to square one
        if (intCurrentStep > 1) {
            SetSizingToolStatusValues(intCurrentStep, intCurrentStep - 1);
        }
    }
    else if (buttonId == "close")
    {
        //$("#advancedSizingToolModal").modal("toggle");
    }
    else if (buttonId == "select")
    {
        // GA event
        trackEventGlobalAndLocal('Bike sizing tool', 'Click', 'Select size')

        // get parntumber
        var partnumberCodesString = $(objButton).attr("data-partnumbercode");
        var partnumberCodes = partnumberCodesString.split(',');

        // get scope
        const scope = angular.element(document.querySelector('[ng-controller="Shop"]')).scope();

        // select
        scope.selectPartnumberByCode(partnumberCodes);

        // close modal
        $("#advancedSizingToolModal").modal("toggle");

    }
    else if (buttonId == "toggle-explanation" || buttonId == "close-explanation" || buttonId == "toggle-explanation-bigger") {

        $("#advancedsizingtool").toggleClass("explanation-active");

    }
    else
    {
        // next step then hide explanation
        $("#advancedsizingtool").removeClass("explanation-active");

        // handle steps
        if (intCurrentStep == 1) {

            // GA event
            trackEventGlobalAndLocal('Bike sizing tool', 'Click', 'Set gender, body length, shoe size');

            // get gender body length and shoe size
            var gender = $("input[name='gender']:checked").val();
            var bodylength_cm = $("input#bodylength_cm").val();
            var bodylength_feet = $("input#bodylength_feet").val();
            var bodylength_inch = $("input#bodylength_inch").val();
            var shoesizetype = $("input#shoesizetype").val();
            var shoesize = $(".shoetype-mode-" + shoesizetype.toLowerCase() + " select#shoesize").val();
            var errorInBodyDimensions = false;

            // Do some basic validations
            if (bodylength_cm) {
                var minBodyLengthCm = $("input#bodylength_cm").attr("min");
                var maxBodyLengthCm = $("input#bodylength_cm").attr("max");
                var intBodyLengthCm = +bodylength_cm;
                if (intBodyLengthCm < minBodyLengthCm || intBodyLengthCm > maxBodyLengthCm) {
                    errorInBodyDimensions = true;
                }
            }

            // save in cookie.
            SaveSizingValuesInCookie(gender, bodylength_cm, bodylength_feet, bodylength_inch, shoesize);
             
            // hide gender specific texts
            $(".humans-with-your-size").empty();

            // validation
            if (gender == "" || gender == undefined) {
                $("#validation-gender").addClass("visible");
                addUp = false;
                hasMissingValue = true;
            }
            if (bodylength_cm == "" && bodylength_feet == "") {
                $("#validation-bodylength").addClass("visible");
                addUp = false;
                hasMissingValue = true;
            }
            if (errorInBodyDimensions) {
                $("#validation-bodylength_incorrect").addClass("visible");
                addUp = false;
                hasMissingValue = true;
            }

            if (hasMissingValue)
            {
                // don't submit and don't add up
            }
            else
            {

                // get body dimensions
                $.ajax({
                    type: "GET",
                    url: baseApiUrl + "/api/BikeFit/BodyDimensionsWithUnitConversion/",
                    data: {
                        culture: cultureFull,
                        gender: gender,
                        bodylength_cm: bodylength_cm,
                        bodylength_feet: bodylength_feet,
                        bodylength_inch: bodylength_inch,
                        shoesize: shoesize,
                        shoesizetype: shoesizetype
                    },
                    success: function (response) {

                        log("Succesfully retrieved body dimensions.");

                        if (response.Success) {

                            // prefill values MM
                            var totalLengthMM = response.BodyDimensions.TotalLengthMM;
                            var totalLengthFEET = response.BodyDimensions.TotalLengthFEET;
                            var totalLengthINCH = response.BodyDimensions.TotalLengthINCH;
                            var totalLengthCM = (totalLengthMM / 10);

                            // prefill and create sliders
                            SetBodySizeValueAndCreateSlider("innerleglength", bodylengthunit, response.BodyDimensions.LegLengthMM, response.BodyDimensions.LegLengthINCH);
                            SetBodySizeValueAndCreateSlider("femurlength", bodylengthunit, response.BodyDimensions.FemurLengthMM, response.BodyDimensions.FemurLengthINCH);
                            SetBodySizeValueAndCreateSlider("torsolength", bodylengthunit, response.BodyDimensions.TorsoLengthMM, response.BodyDimensions.TorsoLengthINCH);
                            SetBodySizeValueAndCreateSlider("armlength", bodylengthunit, response.BodyDimensions.ArmLengthMM, response.BodyDimensions.ArmLengthINCH);

                            // bodylength in MM
                            $("input#bodylength_mm").val(totalLengthMM);

                            // text
                            PopulateBodyLengthAndShoeSizePlaceholders(bodylengthunit, totalLengthCM, totalLengthFEET, totalLengthINCH, shoesize, gender);

                            // enable backbutton
                            $("button#back").show();
                            // remove intro
                            $("p.intro").hide();

                        }
                        else {
                            log("Something went wrong getting the body measures");
                            $("p#message").text(messageSorryCouldNotFind);

                        }
                    },
                    error: function (error) {
                        $("p#message").text(messageOopsSomethingWentWrong); //"Oops, something went wrong getting the body measures."
                        log("An error occured");
                        log(error);
                    }
                });
            }
            
        }
        else if (intCurrentStep == "2")
        {
            // GA event
            trackEventGlobalAndLocal('Bike sizing tool', 'Click', 'Set inner leg length');
        }
        else if (intCurrentStep == "3") {
            // GA event
            trackEventGlobalAndLocal('Bike sizing tool', 'Click', 'Set femur length');
        }
        else if (intCurrentStep == "4") {
            // GA event
            trackEventGlobalAndLocal('Bike sizing tool', 'Click', 'Set torso length');
        }
        else if (intCurrentStep == "5")
        {
            // GA event
            trackEventGlobalAndLocal('Bike sizing tool', 'Click', 'Set arm length');
                      
            // get all values
            var gender = $("input[name='gender']:checked").val();
            var bodylength_mm = $("input#bodylength_mm").val();
            var shoesizetype = $("input#shoesizetype").val();
            var shoesize = $(".shoetype-mode-" + shoesizetype.toLowerCase() + " select#shoesize").val();
            var innerleglengthCmValue = $("input#innerleglength_cm").val();
            var femurlengthCmValue = $("input#femurlength_cm").val();
            var torsolengthCmValue = $("input#torsolength_cm").val();
            var armlengthCmValue = $("input#armlength_cm").val();
            var innerleglengthInchValue = $("input#innerleglength_inch").val();
            var femurlengthInchValue = $("input#femurlength_inch").val();
            var torsolengthInchValue = $("input#torsolength_inch").val();
            var armlengthInchValue = $("input#armlength_inch").val();
            var bodylength_feet = $("input#bodylength_feet").val();
            var bodylength_inch = $("input#bodylength_inch").val();

            // remove previous popouts
            $(".size .popout").remove();

            // call
            $.ajax({
                type: "GET",
                url: baseApiUrl + "/api/BikeFit/BestFitFrameSizeAndPartnumber",
                data: {
                    culture: cultureFull,
                    bikeModelId: productId,
                    gender: gender,
                    bodylength_mm: bodylength_mm,
                    shoesizetype: shoesizetype,
                    shoesize: shoesize,
                    innerleglength_cm: innerleglengthCmValue,
                    femurlength_cm: femurlengthCmValue,
                    torsolength_cm: torsolengthCmValue,
                    armlength_cm: armlengthCmValue,
                    innerleglength_inch: innerleglengthInchValue,
                    femurlength_inch: femurlengthInchValue,
                    torsolength_inch: torsolengthInchValue,
                    armlength_inch: armlengthInchValue,
                    bodylength_feet: bodylength_feet,
                    bodylength_inch: bodylength_inch
                },
                success: function (response) {

                    // check response
                    if (response.RecommendedFrameSizeId) {

                        // get value
                        var recommendedSizeName = response.RecommendedFrameSizeName;
                        var globalFrameSizeId = response.RecommendedFrameSizeId;
                        var partnumberCodes = response.PartnumberCodes;
                        var sizeFitIndicator = response.SizeFitIndicator;
                        
                        // show result 
                        $("div#result-wrapper").show();
                             
                        // create div inside size box
                        //var deviationHtml = "";
                        var deviationHtml = "<a href='#' style='left:calc(" + sizeFitIndicator + "% - 10px);' class='deviation-line' title='" + thisIsYouText + "'><i class='glyphicon glyphicon-play'></i><span class='thisisyou'>"+ thisIsYouText +"</span></a>";
                        $("#framesize" + globalFrameSizeId).append("<div class='popout'><span class='title'>" + recommendedSizeName + "</span>"+ deviationHtml +"</div>");
                            
                        // set partnumber to select button
                        if (partnumberCodes && partnumberCodes.length > 0) {
                            $("button#select").attr("data-partnumbercode", partnumberCodes.join(','));     
                        }
                        $("button#select").show();
                    }
                    else {
                        $("p#message").html(messageNoSizeForYou);
                        $("div#result-wrapper").hide();
                        $("button#select").hide();
                    }
                },
                error: function (error) {

                    $("p#message").text("Oops, something went wrong getting the body measures.")

                    log("an error occured");
                    log(error);
                }
            });

        }

        // add up
        if (addUp) {
            var intNewStep = intCurrentStep + 1;
            if (intNewStep == 7) { intNewStep = 6; }
            // handle status
            if (intCurrentStep <= 6) {
                SetSizingToolStatusValues(intCurrentStep, intNewStep)
            }
        }

       

    }
}

function SetBodySizeValueAndCreateSlider(fieldName, bodyLengthUnit, lengthMM, lengthINCH) {

    if (bodyLengthUnit == "INCH") {
        // INCH
        $("input#" + fieldName + "_inch").val(lengthINCH);

        // DEVIATION
        var lengthINCHmin = lengthINCH * 0.95;
        var lengthINCHmax = lengthINCH * 1.05;

        // ROUND
        lengthINCHmin = Math.round(lengthINCHmin * 10) / 10;
        lengthINCHmax = Math.round(lengthINCHmax * 10) / 10;

        // SLIDER
        $("#" + fieldName + "_inch_slider").quinn({
            min: lengthINCHmin,
            max: lengthINCHmax,
            value: lengthINCH,
            step: 0.1,
            drag: function (newValue, slider) {
                $("input#" + fieldName + "_inch").val(newValue);
            }
        });
    }
    else {
        // CM
        var lengthCM = lengthMM / 10;
        $("input#" + fieldName + "_cm").val(lengthCM);

        // DEVIATION
        var lengthCMmin = lengthCM * 0.95;
        var lengthCMmax = lengthCM * 1.05;
        
        // ROUND
        lengthCMmin = Math.round(lengthCMmin * 10) / 10;
        lengthCMmax = Math.round(lengthCMmax * 10) / 10;
        

        // SLIDER
        $("#" + fieldName + "_cm_slider").quinn({
            min: lengthCMmin,
            max: lengthCMmax,
            value: lengthCM,
            step: 0.1,
            drag: function (newValue, slider) {
                $("input#" + fieldName + "_cm").val(newValue);
            }
        });
    }

}

function SetSizingToolStatusValues(intCurrentStep, intNewStep) {
    // switch section
    $(".sizingsection.active").removeClass("active");
    $("#step" + intNewStep).addClass("active");

    // handle indicator, illustrations 
    if (intCurrentStep < 6) {
        $(".indicator .item.active").removeClass("active");
        $(".indicator #item" + intNewStep).addClass("active");
    }
    // illustrations
    $("#illustrations img.active").removeClass("active");
    $("img#illustration" + intNewStep).addClass("active");
   
    // set new step as current
    $("#advancedsizingtool form input#currentstep").val(intNewStep);

    // add active class to container
    $("#advancedsizingtool").removeClass("activestep" + intCurrentStep);
    $("#advancedsizingtool").addClass("activestep" + intNewStep);
}

function PopulateBodyLengthAndShoeSizePlaceholders(bodylengthunit, totalLengthCM, totalLengthFEET, totalLengthINCH, shoeSize, gender)
{
    
    $("p.assumption").each(function () {

        var assumptionId = this.id;
        
        // get raw text for gender
        var newText = "";
        if (gender == "2") {
            newText = $("p#"+ assumptionId +" .women-with-your-size-rawtext").html();
        } else {
            var newText = $("p#" + assumptionId +" .men-with-your-size-rawtext").html();
        }

        // shoesize
        var newText = newText.replace("#shoesize#", shoeSize);
        // body length
        if (bodylengthunit == "INCH") {
            newText = newText.replace("#bodylength#", totalLengthFEET + "\"" + totalLengthINCH + "'");
        } else {
            newText = newText.replace("#bodylength#", totalLengthCM + " cm");
        }

        // set
        $("p#" + assumptionId + " .humans-with-your-size").html(newText);

    });
    

}


function SaveSizingValuesInCookie(gender, bodylength_cm, bodylength_feet, bodylength_inch, shoesize)
{
    Cookies.set('sizingtool_gender', gender);
    Cookies.set('sizingtool_shoesize', shoesize);
    if (bodylength_cm != "" && bodylength_cm !== undefined) {
        Cookies.set('sizingtool_bodylength_cm', bodylength_cm);
    }
    if (bodylength_feet != "" && bodylength_feet !== undefined) {
        Cookies.set('sizingtool_bodylength_feet', bodylength_feet);
    }
    if (bodylength_inch != "" && bodylength_inch !== undefined) {
        Cookies.set('sizingtool_bodylength_inch', bodylength_inch);
    }
}

function PrefillSizingValuesFromCookie()
{
    // gender
    var gender_from_cookie = Cookies.get('sizingtool_gender');
    if (gender_from_cookie != "" && gender_from_cookie !== undefined) {
        $('input:radio[name=gender][value=' + gender_from_cookie + ']').click();
    }
    // body length
    var bodylength_cm_from_cookie = Cookies.get('sizingtool_bodylength_cm');
    if (bodylength_cm_from_cookie != "" && bodylength_cm_from_cookie !== undefined) {
        $("#advancedsizingtool input#bodylength_cm").val(bodylength_cm_from_cookie);
    }
    var bodylength_feet_from_cookie = Cookies.get('sizingtool_bodylength_feet');
    if (bodylength_feet_from_cookie != "" && bodylength_feet_from_cookie !== undefined) {
        $("#advancedsizingtool input#bodylength_feet").val(bodylength_feet_from_cookie);
    }
    var bodylength_inch_from_cookie = Cookies.get('sizingtool_bodylength_inch');
    if (bodylength_inch_from_cookie != "" && bodylength_inch_from_cookie !== undefined) {
        $("#advancedsizingtool input#bodylength_inch").val(bodylength_inch_from_cookie);
    }
    // shoesize
    var shoesize_from_cookie = Cookies.get('sizingtool_shoesize');
    if (shoesize_from_cookie != "" && shoesize_from_cookie !== undefined) {
        $("#advancedsizingtool select#shoesize").val(shoesize_from_cookie);
    }
}



/* -------- OLD SIZING WIZARD ------------  */


/*
function HandleSizingWizard(objButton) {

    // controller url
    var sizingWizardUrl = "/" + culture + "/bikes/sizingwizard";

    // get button id
    var buttonId = objButton.id;

    if (buttonId == "getbodymeasurements") {

        
        var gender = $("input[name='gender']:checked").val();
        var fullbodylength = $("input#fullbodylength").val();
        var fullbodylength_feet = $("input#fullbodylength_feet").val();
        var fullbodylength_inch = $("input#fullbodylength_inch").val();
        var shoesize = $("select#shoesize").val();
        var shoesizetype = $("input#shoesizetype").val();

        // validation
        if (gender == "" || gender == undefined) {
            $("#validation-gender").addClass("visible");
        }
        if (fullbodylength == "" && fullbodylength_feet == "") {
            $("#validation-fullbodylength").addClass("visible");
        }

        // call 

        $.ajax({
            type: "GET",
            url: sizingWizardUrl,
            data: { step: 1, gender: gender, fullbodylength: fullbodylength, fullbodylength_feet: fullbodylength_feet, fullbodylength_inch: fullbodylength_inch, shoesize: shoesize, shoesizetype: shoesizetype },
            success: function (response) {

                if (response.Success) {

                    // set active step 
                    $(".step").removeClass("step-active");
                    $(".step-two").addClass("step-active");
                    
                    // set step
                    $("input#step").val("2");  

                    // prefill values MM
                    var totalLengthMM = response.BodyDimensions.TotalLengthMM;
                    var innerLegLengthMM = response.BodyDimensions.LegLengthMM;
                    var femurLengthMM = response.BodyDimensions.FemurLengthMM;
                    var torsoLengthMM = response.BodyDimensions.TorsoLengthMM;
                    var armLengthMM = response.BodyDimensions.ArmLengthMM;

                    // prefill values INCH
                    var innerLegLengthINCH = response.BodyDimensions.LegLengthINCH;
                    var femurLengthINCH = response.BodyDimensions.FemurLengthINCH;
                    var torsoLengthINCH = response.BodyDimensions.TorsoLengthINCH;
                    var armLengthINCH = response.BodyDimensions.ArmLengthINCH;

                    // CM
                    $("input#innerleglength").val(innerLegLengthMM / 10);
                    $("input#femurlength").val(femurLengthMM / 10);
                    $("input#torsolength").val(torsoLengthMM / 10);
                    $("input#armlength").val(armLengthMM / 10);

                    // INCHES
                    $("input#innerleglength_inch").val(innerLegLengthINCH);
                    $("input#femurlength_inch").val(femurLengthINCH);
                    $("input#torsolength_inch").val(torsoLengthINCH);
                    $("input#armlength_inch").val(armLengthINCH);

                    // pass through previous values
                    $("input#genderSaved").val(gender);
                    $("input#shoesizeSaved").val(shoesize);

                    // fullbodylength
                    $("input#fullbodylengthSaved").val(totalLengthMM);

                 
                }
                else {
                    log("something went wrong getting the body measures");
                    $("p#message-step-one").text("Sorry, I couldn't find body measures for these values.")
                }
            },
            error: function (error) {

                $("p#message-step-one").text("Oops, something went wrong getting the body measures.")

                log("an error occured");
            }
        });

        
    }
    else if (buttonId == "getavailablesizes") {
        log("get available sizes");

        var gender = $("input[name='gender']:checked").val();
        var fullbodylength = $("input#fullbodylength").val();
        var fullbodylength_feet = $("input#fullbodylength_feet").val();
        var fullbodylength_inch = $("input#fullbodylength_inch").val();
        var shoesize = $("select#shoesize").val();
        var shoesizetype = $("input#shoesizetype").val();

        // validation
        if (gender == "" || gender == undefined) {
            $("#validation-gender").addClass("visible");
        }
        if (fullbodylength == "" && fullbodylength_feet == "") {
            $("#validation-fullbodylength").addClass("visible");
        }

        var genderSaved = $("input#genderSaved").val();
        var fullbodylengthSaved = $("input#fullbodylengthSaved").val();
        var shoesizeSaved = $("input#shoesizeSaved").val();
        var shoesizetype = $("input#shoesizetype").val();
        var innerleglengthValue = $("input#innerleglength").val();
        var femurlengthValue = $("input#femurlength").val();
        var torsolengthValue = $("input#torsolength").val();
        var armlengthValue = $("input#armlength").val();
        var innerleglengthInchValue = $("input#innerleglength_inch").val();
        var femurlengthInchValue = $("input#femurlength_inch").val();
        var torsolengthInchValue = $("input#torsolength_inch").val();
        var armlengthInchValue = $("input#armlength_inch").val();
       

        // call 
        $.ajax({
            type: "GET",
            url: sizingWizardUrl,
            data: { step: 1, gender: gender, fullbodylength: fullbodylength, fullbodylength_feet: fullbodylength_feet, fullbodylength_inch: fullbodylength_inch, shoesize: shoesize, shoesizetype: shoesizetype, bikeModelId: productId },
            // data: { step: 2, gender: genderSaved, fullbodylength: fullbodylengthSaved, shoesizetype: shoesizetype, shoesize: shoesizeSaved, innerleglength: innerleglengthValue, femurlength: femurlengthValue, torsolength: torsolengthValue, armlength: armlengthValue, innerleglength_inch: innerleglengthInchValue, femurlength_inch: femurlengthInchValue, torsolength_inch: torsolengthInchValue, armlength_inch: armlengthInchValue, bikeModelId: productId },
            success: function (response) {

                //log(response);

                if (response.Success) {

                    log("Succesfully retrieved recommended size");

                    // hide step 1 show 2
                    $(".step").removeClass("step-active");
                    $(".step-two").addClass("step-active");

                    // get recommended size
                    var recommendedSize = "?";
                    var globalFrameSizeId = null;

                    // check response
                    if (response.GlobalFrameSizeExtended != undefined && response.GlobalFrameSizeExtended.GlobalFrameSize != undefined) {

                        // get value
                        recommendedSize = response.RecommendedFrameSizeName;
                        globalFrameSizeId = response.RecommendedFrameSizeId;
                        partnumberCode = response.PartnumberCode;

                        // set size in box and hidden
                        $("#sizebox").text(recommendedSize);
                        $("input#recommendedSize").val(recommendedSize);
                        $("input#globalFrameSizeId").val(globalFrameSizeId);
                        $("input#partnumberCode").val(partnumberCode);

                        // animate
                        $("#sizebox").addClass("animate");

                        // define active step 
                        $("input#step").val("3");

                    }
                    else {
                        $("p#message").text("No Framesize Found")
                    }

                }
                else {

                    $("p#message-step-one").text("No size recommended for these body measurements.");

                }
            },
            error: function (error) {

                $("p#message-step-one").text("Oops, something went wrong getting the availble sizes");


            }
        });

    }
    else if (buttonId == "selectsize") {
        // get partnumbercode
        var partnumberCodeValue = $("input#partnumberCode").val();

        if (partnumberCodeValue != "undefined" && partnumberCodeValue != "") {
            // get scope
            const scope = angular.element(document.querySelector('[ng-controller="Shop"]')).scope();

            // select
            scope.selectPartnumberByCode(partnumberCodeValue);

            // close modal
            $('#sizingWizardModal').modal('toggle');

            // reset wizard
            $(".step").removeClass("step-active");
            $(".step-one").addClass("step-active");
            $("input#step").val("1");

        }
        else {
            $("p#message-step-three").text("Sorry, I cannot find a barcode");
        }

    }
    else if (buttonId == "backtostep1") {
        // reset wizard
        $(".step").removeClass("step-active");
        $(".step-one").addClass("step-active");
        $("input#step").val("1");
        $("p#message-step-two").text("");
    }

}
*/

/*
function HandleSizingWizardImage(myEvent) {
    if (myEvent.target != null && myEvent.target.nodeName == "SPAN") {
        // active classes
        $(".sizingwizard span.trigger-image").removeClass("active");
        $(myEvent.target).addClass("active");
    }

    // get type
    var imageType = $(myEvent.target).attr("data-type");
    // define src
    var imageSrc = cdn + "/Images/Shared/Bikes/sizingwizard_" + imageType + ".jpg";
    // set src
    $("img.measurement-image").attr("src", imageSrc);
}

*/