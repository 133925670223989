function ToggleMegaMenu(code) {

    var onlyHide = $("#megamenu" + code).hasClass("megamenu-active");
    //hide current active
    $(".megamenu-active").slideUp();
    $(".megamenu-active").removeClass("megamenu-active");
    $('body').css({ overflow: onlyHide ? 'auto' : 'hidden', width: '100%', height: '100%' });

    // set active class
    if (!onlyHide) {
        $("#megamenu" + code).toggleClass("megamenu-active");

        // Show mega menu
        $("#megamenu" + code).slideToggle();

        if (code == 'search') {
            //set focus
            $('#search').focus();
        }
    }
    /* */

    // Hide page behind transparent black overlay
    var hasActiveClass = $("#megamenu" + code).hasClass("megamenu-active");
    if (hasActiveClass == true && onlyHide == false) {
        $(".overlay").addClass("overlay-active");
        if (code == "search") {
            $("#megamenusearch form input#q").focus();
        }
    } else {
        $(".overlay").removeClass("overlay-active");
    }
}

function findBootstrapEnvironment() {
    var envs = ['xs', 'sm', 'md', 'lg'];

    var $el = $('<div>');
    $el.appendTo($('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-' + env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env;
        }
    }
}

function isMobile() {
    return findBootstrapEnvironment() === 'xs';
}

function swipedetect(el, callback) {
    var touchsurface = el,
        swipedir,
        startX,
        startY,
        distX,
        distY,
        threshold = 75,
        restraint = 100,
        allowedTime = 300,
        elapsedTime,
        startTime,
        handleswipe = callback || function (swipedir) { };

    touchsurface.addEventListener('touchstart', function (e) {
        var touchobj = e.changedTouches[0];
        swipedir = 'none';
        dist = 0;
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime();
    }, false)

    touchsurface.addEventListener('touchmove', function (e) {
        e.preventDefault();
    }, false)

    touchsurface.addEventListener('touchend', function (e) {
        var touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX;
        distY = touchobj.pageY - startY;
        elapsedTime = new Date().getTime() - startTime;
        if (elapsedTime <= allowedTime) {
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
                swipedir = (distX < 0) ? 'left' : 'right';
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
                swipedir = (distY < 0) ? 'up' : 'down';
            }
        }
        handleswipe(swipedir);
    }, false)
}

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
    e.returnValue = false;
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

function disableScroll() {
    if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false);
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove = preventDefault; // mobile
    document.onkeydown = preventDefaultForScrollKeys;
}

function enableScroll() {
    if (window.removeEventListener)
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
}

// www.stackoverflow.com/a/7557433/533460
function isElementInViewport(el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}


function HandleScrollMenuClick() {
    // anchor click
    $(".scrollmenu a").click(function (event) {

        // prevent anchor link
        event.preventDefault();

        // set link state
        $(".scrollmenu a").removeClass("active");

        var linkId = this.id;
        $(".scrollmenu a#" + linkId).addClass("active");

        // scroll
        myHref = $(this).attr("href");
        ScrollToTarget(myHref);

        // set focus to target
        $("div" + myHref).focus();

    });
}


function ScrollToTarget(myHref) {

    // for more precision
    var minusPixels = 165;

    // get target(
    var target = $(myHref);
    if (target.length) {
        // define new position
        var newPosition = target.offset().top;
        newPosition = newPosition - minusPixels;
        // animate to it
        $('html, body').animate({
            scrollTop: newPosition
        }, 1000);
    }
}

function HandleScrollMenuState() {
    // window scrolling
    $(window).scroll(function () {

        $(".scrollmenu-target").each(function () {
            var myId = this.id
            var isVisible = isElementInViewport(this);
        });

    });
}

function HandleRiderSpecs() {
    nrOfRows = $("#specs table tr").length;
    halfWay = Math.ceil((nrOfRows / 3) * 2);
    index = 1;
    firstHalf = "";
    secondHalf = "";

    $("#specs table tr").each(function () {

        if (index <= halfWay) {
            firstHalf += "<tr>" + $(this).html() + "</tr>";
        } else {
            secondHalf += "<tr>" + $(this).html() + "</tr>";
        }
        index++;
    });

    $("#rider-table-left").append("<table>" + firstHalf + "</table>");
    $("#rider-table-right").append("<table>" + secondHalf + "</table>");

}

//function checkIfAddToCartButtonIsThereAndDoStuff() {
//    if ($("button.btn-addtocart").length) {
//        $("form#clickandcollect").addClass("has-add-to-cart-button");
//    } else {
//        $("form#clickandcollect").removeClass("has-add-to-cart-button");
//    }
//}

//function ShowCartSummary() {
//    function implement(data, noSlide) {
//        $('.cart-summary-popup .body').html(data);

//        if (noSlide !== true) {
//            $('.cart-summary-popup').slideDown();
//            $('body').on('click', function (e) {
//                if ($(e.target).parents('.cart-summary-popup').length == 0) {
//                    $('.cart-summary-popup').slideUp();
//                    $('body').unbind(e);
//                }
//            });
//        }

//        $('.remove-cart-item').on('click', function (e) {
//            $.getJSON('/' + culture + '/shop/removecartitem/' + $(this).data('partnumber') + '/json', {
//                cache: false
//            }, function (data) {
//                if (data.CartQuantity > 0) {
//                    $('.remove-cart-item').off('click');
//                    $('#summary-NumberOfItems, .NumberOfItems')
//                        .html(data.CartQuantity);
//                    $('#summary-NumberOfItemsLabel').html(data.CartQuantity > 1 ? 'items' : 'items');
//                    $('#summary-total').html(data.CartTotal);
//                    $('.cart-summary-container').removeClass('cart-summary-empty');
//                    implement(data.Html, true);
//                } else {
//                    $('.cart-summary-popup').slideUp();
//                    $('.cart-summary-container').addClass('cart-summary-empty');
//                    $('#summary-NumberOfItems, .NumberOfItems').html(0);
//                    $('#summary-NumberOfItemsLabel').html('');
//                    $('a.cart-summary-container').removeAttr('href');
//                    $('#summary-total').html('');
//                }
//            });
//        });

//        $('.update-cart-item').on('click', function (e) {
//            var quantity = parseInt($('#cartItem-' + $(this).data('partnumber') + ' .cartItem-quantity').html());
//            if ($(this).data('update') == 'plus') {
//                quantity = 1;
//            } else {
//                quantity = -1;
//            }
//            //if (quantity > 0) {
//            $.ajax({
//                type: 'POST',
//                url: '/' + culture + '/shop/updatecartitem',
//                data: { partnumberCode: $(this).data('partnumber'), modelId: $(this).data('modelid'), productType: $(this).data('producttype'), quantity: quantity },
//                success: function (data) {
//                    $('#summary-NumberOfItems, .NumberOfItems').html(data.CartQuantity);
//                    $('#summary-NumberOfItemsLabel').html(data.CartQuantity > 1 ? 'items' : 'items');
//                    $('#summary-total').html(data.CartTotal);
//                    $('.cart-summary-container').removeClass('cart-summary-empty');
//                    implement(data.Html, true);
//                }
//            });
//            //}
//        });
//    }

//    //get cart table realtime
//    if ($('.cart-summary-popup').is(':visible')) {
//        $('.cart-summary-popup').slideUp();
//    } else {
//        $.ajax({
//            type: 'GET',
//            url: '/' + culture + '/shop/getcartTable',
//            cache: false,
//            success: implement
//        });
//    }
//}

//function AddCartItem(productId, partnumberCode, productType, quantity, dealerCode, continueShoppingUrl, extraLabel, globalCatalogue) {
//    $.ajax({
//        type: 'POST',
//        url: '/' + culture + '/shop/addcartitem',
//        data: { productId: productId, partnumberCode: partnumberCode, productType: productType, quantity: quantity, dealerCode: dealerCode, globalCatalogue: globalCatalogue },
//        success: function (data) {
//            //if ($('#cart-table').length == 0) {
//            if (data.result > 0) {
//                $('#ModalLocalStockFinder').modal('hide');
//                if (data.shoplink != undefined && data.shoplink.length > 0) {
//                    $('#proceedCheckout').attr('href', data.shoplink);
//                }
//                $('#cartModal .modal-body').load('/' + culture + '/shop/cartitemadded?cartItemId=' + data.result, function (_d) {
//                    if (continueShoppingUrl != undefined && continueShoppingUrl.length > 0) {
//                        $('#continueShopping').attr('href', continueShoppingUrl);
//                        $('#continueShopping').removeAttr('data-dismiss');
//                    }
//                    $('#cartModal').modal('show');
//                    AnalyticsBuilder.create(inTestMode ? { debug: true } : {}).addProduct({
//                        'id': partnumberCode,
//                        'name': $('#cartModal').find('.productname').text(),
//                        'quantity': quantity
//                    }).asAddToCart().send({
//                        label: extraLabel && extraLabel.length ? extraLabel : 'Add to cart'
//                    });
//                });
//                UpdateCartSummary();
//            }
//            else {
//                alert(data.exceptionmessage);
//            }
//        }
//    });
//}

//function UpdateCartSummary() {
//    $.ajax({
//        type: 'GET',
//        url: '/' + culture + '/shop/getcartsummary',
//        cache: false,
//        success: function (data) {
//            $('#summary-NumberOfItems, .NumberOfItems').html(data.NumberOfCartItems);
//            $('#summary-NumberOfItemsLabel').html(data.NumberOfCartItems > 1 ? 'items' : 'item');
//            $('#summary-total').html(data.TotalToFormat);
//            if (data.NumberOfCartItems > 0) {
//                $('.cart-summary-container').removeClass('cart-summary-empty');
//            } else {
//                $('.cart-summary-container').addClass('cart-summary-empty');
//            }
//            $('#summary-container').fadeIn();
//        }
//    });
//}

//function UpdateCart(includeValidation, callback) {
//    if (includeValidation == undefined) {
//        includeValidation = false;
//    }
//    $.ajax({
//        type: 'GET',
//        url: '/' + culture + '/shop/getcart?includeValidation=' + includeValidation,
//        cache: false,
//        success: function (data) {
//            if (data.NumberOfCartItems == 0) {
//                //remove table
//                $('#cart-table').remove();
//                $('#cart-empty').fadeIn();
//            } else {
//                $('.cart-total').html(data.TotalIncludingShippingCostsToFormat);
//                $('.cart-tax').html(data.TaxToFormat);
//                $('.shipping-total').html(data.ShippingToFormat);
//            }

//            if (document.getElementsByClassName("affirm-learn-more").length > 0) {
//                //update Affirm value
//                updateAffirmAsLowAs(document.getElementsByClassName("affirm-learn-more")[0], data.TotalIncludingShippingCosts * 100);
//            }

//            //Set shipping options
//            data.ShippingOptions.forEach(function (item) {
//                if (item.Guid == data.ShippingGuid) {
//                    $('#shippingLabel').html(item.Name);
//                }
//            });

//            //Set discount
//            if (data.Discount > 0) {
//                $('.cart-discount').html(data.DiscountToFormat);
//                $('.discountContainer').show();
//            } else {
//                $('.discountContainer').hide();
//            }

//            if (callback) {
//                callback();
//            }
//        }
//    });
//}

//function UpdateCartItem(cartItemId) {
//    $.ajax({
//        type: 'GET',
//        url: '/' + culture + '/shop/getcartitem?id=' + cartItemId,
//        success: function (data) {
//            var tr = $('tr#cartItem-' + cartItemId);
//            tr.find('.cartItem-quantity').html(data.Quantity);
//            tr.find('.cartItem-total').html(data.TotalToFormat);

//            if (data.Quantity == 0) {
//                //remove cart item, because quantity is 0
//                RemoveCartItem(cartItemId);
//            }
//        }
//    });
//}

//function SetShippingOption(shippingOption) {
//    var shippingOptionType = $('#' + shippingOption).attr('data-deliverytype');
//    $.ajax({
//        type: 'POST',
//        url: '/' + culture + '/shop/setshippingoption',
//        data: { guid: shippingOption },
//        success: function (data) {
//            if (shippingOptionType == 'homedelivery' && localstock == false && $('#dealerSelected').length > 0 && $('#dealerSelected').val() == 'true') {
//                //remove dealer when switching to homedelivery and not local stock
//                window.location = '/' + culture + '/shop/removedealercode';
//            }
//            UpdateCart();
//            //CheckToGetDealers();
//        }
//    });
//}

//function SetPaymentOption(paymentOption) {
//    $.ajax({
//        type: 'POST',
//        url: '/' + culture + '/shop/setpaymentoption',
//        data: { code: paymentOption },
//        success: function (data) {
//            //UpdateCart();
//        }
//    });
//}

//function SetState(state) {
//    $.ajax({
//        type: 'POST',
//        url: '/' + culture + '/shop/setstate',
//        data: { state: state },
//        success: function (data) {
//            UpdateCart();
//        }
//    });
//}

//function Login(email, password) {
//    $('#loginModal').find('.alert').remove();
//    $.ajax({
//        type: 'POST',
//        url: '/' + culture + '/shop/login',
//        data: { email: email, password: password },
//        success: function (data) {
//            var showErrorMessage = true;
//            for (var property in data) {
//                if (data[property] != null && data[property] != 0) {
//                    showErrorMessage = false;
//                    $('input[name="Order.' + property + '"]').val(data[property]);
//                    $('select[name="Order.' + property + '"]').val(data[property]);
//                }
//            }
//            if (showErrorMessage) {
//                $('#loginModal .modal-body').prepend('<div class="alert alert-danger">No user found</div>');
//            } else {
//                $('#loginModal').modal('hide');
//                //show different delivery address if specified
//                if ($('input[name="Order.OrderDeliveryStreet"]').length > 0) {
//                    $('#deliveryAddressContainer').show();
//                    $('#lnkShowDifferentDeliveryAdress').remove();
//                }
//                //rename create account label
//                $('#lnkCreateAccount').html('Change password');
//                //CheckToGetDealers();
//            }
//        }
//    });
//}

//function CheckToGetDealers() {
//    if ($('#dealerList').length > 0 && $('#Order_OrderStreet').val().length > 0 && $('#Order_OrderPostalcode').val().length > 0 && $('#Order_OrderCity').val().length > 0) {
//        //get list of dealers when there is no directshipment and naw data is filled.
//        var address = country + ', ' + $('#Order_OrderStreet').val() + ', ' + $('#Order_OrderPostalcode').val() + ', ' + $('#Order_OrderCity').val();

//        GetDealers(address, function (data) {
//            var dealerList = $('#dealerList');
//            dealerList.html('');
//            if (data.length > 0) {
//                var result = '<ul style="list-style:none;padding-left:0;">';
//                var checked = 'checked="checked"';
//                data.forEach(function (item) {
//                    result += '<li><input id="' + item.Code + '" name="Order.OrderDealercode" type="radio" value="' + item.Code + '" ' + checked + '/><label style="margin-left:10px;" class="" for="' + item.Code + '">' + item.Name + '</label><p style="padding-left:22px;">' + item.Street + ', ' + item.Zipcode + ', ' + item.City + ' (' + item.DistanceToFormat + ' ' + measurement + ')</p>' + '</li>';
//                    checked = '';
//                });
//                result += '</ul>';
//                dealerList.append(result);
//            } else {
//                dealerList.append('<div class="alert alert-danger">No dealer found. Select a different shipping option.</div>');
//            }
//        }, function () {
//            var dealerList = $('#dealerList');
//            dealerList.html('');
//            dealerList.append('<div class="alert alert-danger">No dealer found. Select a different shipping option.</div>');
//        });
//    }
//}

//function GetDealers(address, callback, callbackError) {
//    getLatLng(address, function (lat, lng, formatted_address) {
//        if (lat != undefined) {
//            $.ajax({
//                type: 'POST',
//                url: '/' + culture + '/shop/dealers',
//                data: { latitude: lat, longitude: lng },
//                success: function (data) {
//                    if (typeof callback === 'function') {
//                        callback.apply(null, [data]);
//                    }
//                },
//                error: function (e) {
//                    if (typeof callbackError === 'function') {
//                        callbackError.apply();
//                    }
//                }
//            });
//        } else {
//            if (typeof callbackError === 'function') {
//                callbackError.apply();
//            }
//        }
//    });
//}






//function getAddressFromGoogle() {
//    var address = $("#Keyword").val() + ' ' + country;
//    address = address.replace(',', '');
//    var $latLng = $("#LatLng");
//    var $formattedAddress = $("#FormattedAddress");

//    // Get lat/lng value, add it to the hidden LatLng field and submit the form
//    getLatLng(address, function (lat, lng, formatted_address) {
//        if (lat != undefined && lng != undefined) {
//            $latLng.val(lat + ',' + lng);
//        } else {
//            $latLng.val('');
//        }
//        $formattedAddress.val(formatted_address);
//        readyToSubmit = true;
//        $("form#Geocoder").submit();
//    });
//}

// this function takes two parameters
// the address as a string from the input field
// and a callback it will invoke with the results
// when it's done
function getLatLng(address, callback) {

    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({ 'address': address }, function (results, status) {
        var lat, lng, formatted_address;
        if (status == google.maps.GeocoderStatus.OK) {

            // Get the Lat. and Long.
            lat = results[0].geometry.location.lat();
            lng = results[0].geometry.location.lng();
            formatted_address = results[0].formatted_address;
            //} else {
            //    Raygun.send("Google Maps Geocoder exception. Status: " + status);
        } else {
            alert('Geocode was not successful for the following reason: ' + status);
        }

        // Pass latLng to callback function
        if (typeof callback === 'function') {
            callback.apply(null, [lat, lng, formatted_address, results]);
        }
    });
}

var gettingBrowserLocation = false;
var browserLocation;
function getBrowserLocation(callback, lookup) {
    if (lookup == undefined || lookup == null || lookup == true) {

        if (checkBrowserLocation(callback)) {
            //wait to try again
            if (navigator.geolocation) {
                gettingBrowserLocation = true;
                navigator.geolocation.getCurrentPosition(function (position) {
                    browserLocationSuccess(position, callback);
                }, function (error) {
                    browserLocation = { lat: window.defaultLatitude, lng: window.defaultLongitude, dealercity: window.defaultCityEnglish };
                    gettingBrowserLocation = false;
                    callback(window.defaultLatitude, window.defaultLongitude, window.defaultCityEnglish, true);
                    //if (error.code == error.PERMISSION_DENIED) {
                    //    // pop up dialog asking for location
                    //}
                }, { timeout: 5000 });//5sec
            }
        }
    } else {
        callback();
    }
}

var checkBrowserLocation = function (callback) {
    if (gettingBrowserLocation == false) {
        // run when condition is met
        if (browserLocation != undefined) {
            if (callback) {
                callback(browserLocation.lat, browserLocation.lng, browserLocation.dealercity);
            }
        } else {
            return true;
        }
    }
    else {
        log('wait for browser location acceptance');
        setTimeout(function () {
            checkBrowserLocation(callback);
        }, 500); // check again in a second
    }
}

var browserLocationSuccess = function (position, callback) {
    var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, function (results, status) {
        var dealercity = '';
        if (status == google.maps.GeocoderStatus.OK) {
            if (results[1]) {
                var arrAddress = results;
                log(results);
                $.each(arrAddress, function (i, address_component) {
                    if (address_component.types[0] == "locality") {
                        log("dealercity: " + address_component.address_components[0].long_name);
                        dealercity = address_component.address_components[0].long_name;
                    }
                });
                if (dealercity.length == 0) {
                    //try to find by administrative_area_level_4
                    $.each(arrAddress, function (i, address_component) {
                        if (address_component.types[0] == "administrative_area_level_4") {
                            log("dealercity: " + address_component.address_components[0].long_name);
                            dealercity = address_component.address_components[0].long_name;
                        }
                    });
                }
                if (dealercity.length == 0) {
                    //try to find by postal_town
                    $.each(arrAddress, function (i, address_component) {
                        if (address_component.types[0] == "postal_town") {
                            log("dealercity: " + address_component.address_components[0].long_name);
                            dealercity = address_component.address_components[0].long_name;
                        }
                    });
                }
            } else {
                dealercity = lblNoLocationFound;
            }
        } else {
            //Raygun.send("Google Maps Geocoder exception. Status: " + status);
            dealercity = "Geocoder failed due to: " + status;
        }

        browserLocation = { lat: position.coords.latitude, lng: position.coords.longitude, dealercity: dealercity };
        gettingBrowserLocation = false;

        if (callback && dealercity.length > 0) {
            callback(position.coords.latitude, position.coords.longitude, dealercity);
        }
    });
}

//function getBrowserLocation(callback) {
//    if (navigator.geolocation) {
//        navigator.geolocation.getCurrentPosition(function (position) {
//            if (callback) {
//                callback(position);
//            }
//        });
//    } else {
//        x.innerHTML = "Geolocation is not supported by this browser.";
//    }
//}

/*
function setImageResizeModeForCarousel(elementId) {
    $("#carousel-resizemode-placeholder img").each(function () {

        var referenceClass = $(this).attr("data-reference-class");
        var loopSrc = $(this).attr("src");

        var tmpImg = new Image();
        tmpImg.src = loopSrc;

        $(tmpImg).one('load', function () {
            orgWidth = tmpImg.width;
            orgHeight = tmpImg.height;
            // if portrait
            if (orgHeight >= orgWidth) {

                // get real li of carousel
                objLi = $(elementId + " ul li." + referenceClass);
                backgroundImage = $(objLi).css('background-image');
                newBackgroundImage = backgroundImage.replace("mode=crop", "mode=pad");
                // change it now
                $(objLi).css('background-image', newBackgroundImage);
            }
        });
    });
}
*/

//function createCookie(name, value, days) {
//    var expires;

//    if (days) {
//        var date = new Date();
//        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//        expires = "; expires=" + date.toGMTString();
//    } else {
//        expires = "";
//    }
//    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
//}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

//function eraseCookie(name) {
//    createCookie(name, "", -1);
//}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

//$.fn.optVisible = function (show) {
//    if (show) {
//        this.filter("span > option").unwrap();
//    } else {
//        this.filter(":not(span > option)").wrap("<span>").parent().hide();
//    }
//    return this;
//}

//var Payeezy = function () {
//    function e(e) {
//        var t = {
//            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
//            mastercard: /^5[1-5][0-9]{14}$/,
//            amex: /^3[47][0-9]{13}$/,
//            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
//            discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
//            jcb: /^(?:2131|1800|35\d{3})\d{11}$/
//        };
//        for (var n in t) {
//            if (t[n].test(e)) {
//                return n
//            }
//        }
//    }

//    function t() {
//        var e = [];
//        var t = document.getElementsByTagName("input");
//        var n = document.getElementsByTagName("select");
//        for (i = 0; i < t.length; i++) {
//            var r = t[i];
//            var s = r.getAttribute("payeezy-data");
//            if (s) {
//                e[s] = r.value
//            }
//        }
//        for (i = 0; i < n.length; i++) {
//            var r = n[i];
//            var s = r.getAttribute("payeezy-data");
//            if (s) {
//                e[s] = r.value
//            }
//        }
//        return e
//    }
//    return {
//        createToken: function (e) {
//            var n = (window.inTestMode != undefined && window.inTestMode) || (window.isDeveloperMachine != undefined && window.isDeveloperMachine) ? "api-cert.payeezy.com" : "api.payeezy.com"; //tijdelijk altijd naar live. Was: "api-cert.payeezy.com"
//            this["clientCallback"] = e;
//            var r = t();
//            var i = 0;
//            var s = {};
//            var o = 0;
//            var u = [];
//            if (!this.apikey) {
//                i = 400;
//                u[o] = {
//                    description: "Please set the API Key"
//                };
//                o++
//            }
//            if (!this.js_security_key) {
//                i = 400;
//                u[o] = {
//                    description: "Please set the js_security_key"
//                }
//            }
//            if (!this.ta_token) {
//                i = 400;
//                u[o] = {
//                    description: "Please set the ta_token"
//                }
//            }
//            if (!this.auth) {
//                i = 400;
//                u[o] = {
//                    description: "Please set auth value"
//                }
//            }
//            if (u.length > 0) {
//                s["error"] = {
//                    messages: u
//                };
//                e(i, s);
//                return false
//            }
//            var a = "https://" + n + "/v1/securitytokens?apikey=" + this.apikey + "&js_security_key=" + this.js_security_key + "&callback=Payeezy.callback&auth=" + this.auth + "&ta_token=" + this.ta_token + "&type=FDToken&credit_card.type=" + r["card_type"] + "&credit_card.cardholder_name=" + r["cardholder_name"] + "&credit_card.card_number=" + r["cc_number"] + "&credit_card.exp_date=" + r["exp_month"] + r["exp_year"] + "&credit_card.cvv=" + r["cvv_code"] + "&currency=" + r["currency"] + "&billing_address.city=" + r["city"] + "&billing_address.country=" + r["country"] + "&billing_address.email=" + r["email"] + "&billing_address.phone.type=" + r["type"] + "&billing_address.phone.number=" + r["number"] + "&billing_address.street=" + r["street"] + "&billing_address.state_province=" + r["state_province"] + "&billing_address.zip_postal_code=" + r["zip_postal_code"];
//            var f = document.createElement("script");
//            f.src = a;
//            document.getElementsByTagName("head")[0].appendChild(f)
//        },
//        setApiKey: function (e) {
//            this["apikey"] = e
//        },
//        setJs_Security_Key: function (e) {
//            this["js_security_key"] = e
//        },
//        setTa_token: function (e) {
//            this["ta_token"] = e
//        },
//        setAuth: function (e) {
//            this["auth"] = e
//        },
//        setCurrency: function (e) {
//            this["currency"] = e
//        },
//        callback: function (e) {
//            this["clientCallback"](e.status, e.results)
//        }
//    }
//}();

// global and country event tracker
function trackEventGlobalAndLocal(evtCat, evtAction, evtLabel, non_interaction) {
    var preferences = $.gdprcookie.preference();
    if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
        /*if (window.UseGDPRgtagGA == true) {*/
        window["AnalyticsBuilder"].create(window.inTestMode ? { debug: true } : {})
            .asCategory(evtCat, evtAction, evtLabel, non_interaction);
        //} else {
        //    ga('global.send', 'event', evtCat, evtAction, evtLabel);
        //    ga('CountryTracker.send', 'event', evtCat, evtAction, evtLabel);
        //}
        log('*GDPR-statistics + GDPR-marketing: Google Analytics track event. Cat: ' + evtCat + '. Action: ' + evtAction);
    }
}

function trackEventGlobalAndLocalGA4(variables) {
    var preferences = $.gdprcookie.preference();
    if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1 && window.GoogleAnalyticsGA4Codes != undefined && window.GoogleAnalyticsGA4Codes.length > 0) {
        //var variables = $(object).data();
        if (variables != null) {
            var result = [];
            result['send_to'] = window.GoogleAnalyticsGA4Codes;
            var name = '';

            Object.entries(variables).forEach(function callback(key) {
                if (key[0] == 'ga4_name') {
                    name = key[1].toString().toLowerCase();
                } else if (key[0].startsWith('ga4')) {
                    var _key = key[0].replace('ga4_', '').toString().toLowerCase();
                    if (key[1] == undefined) {
                        key[1] = '';
                    }
                    if (Array.isArray(key[1])) {
                        result[_key] = key[1];
                    } else {
                        result[_key] = key[1] != undefined ? key[1].toString().toLowerCase() : '';
                    }
                }
            });

            if (name.length > 0) {
                window["AnalyticsBuilder"].create(window.inTestMode ? { debug: true } : {}).asEvent(name, result);
                /*log('GA4 track event => Name: ' + name + ', Variables: ' + Object.entries(result));*/
            }
        }
    }
}

function exceptionHandling(message, extra) {
    //Use raygun instead of our api
    try {
        rg4js('send', {
            error: new Error('Manual website exception handling: ' + message + (extra != undefined ? ' => Extra: ' + extra : ''))
        });
    } catch (e) {

    }

    //$.ajax({
    //    type: 'POST',
    //    url: '/api/exception',
    //    data: { message: message, url: window.location.href, extra: extra }
    //});
}

function createProduct(data) {
    var productData = {};
    for (var prop in data)
        if (prop.startsWith("product"))
            productData[prop.replace('product', '').toLowerCase()] = data[prop];
    return productData;
}

var previousScrollPercentage = 0;
var percentage25Fired = false;
var percentage50Fired = false;
var percentage75Fired = false;
var percentage100Fired = false;
function HandleScrollingAndScaling(myObject) {
    var preferences = $.gdprcookie.preference();
    if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
        // SCROLLING
        var distanceToTop = $(myObject).scrollTop();

        // header transparency - or always when no brandswitch
        if (divisionCode == '1000' && distanceToTop > 100) {
            $("body").addClass("scroll-mode-enabled");
        } else if (distanceToTop > lastScrollTop && distanceToTop > 100) {
            $("body").addClass("scroll-mode-enabled");
        } else {
            $("body").removeClass("scroll-mode-enabled");
        }

        // set last scroll position - for upscrolling 
        lastScrollTop = distanceToTop;

        // RESIZING
        if ($(window).width() < 975) {
            $("body").addClass("mobile-mode-enabled");
        }
        else {
            $("body").removeClass("mobile-mode-enabled");
        }

        //Track GA
        var percentage = $(this).scrollTop() / $(document).height() * 100;
        if (previousScrollPercentage < 25 && percentage >= 25 && percentage < 50 && percentage25Fired === false) {
            percentage25Fired = true;
            trackEventGlobalAndLocal('scroll', '25%scrolled', $(document).attr('title'), true);
            //GA4
            trackEventGlobalAndLocalGA4({ 'ga4_name': 'custom_scroll', 'ga4_scroll_depth': '25%' });
        } else if (previousScrollPercentage < 50 && percentage >= 50 && percentage < 75 && percentage50Fired === false) {
            percentage50Fired = true;
            trackEventGlobalAndLocal('scroll', '50%scrolled', $(document).attr('title'), true);
            //GA4
            trackEventGlobalAndLocalGA4({ 'ga4_name': 'custom_scroll', 'ga4_scroll_depth': '50%' });
        } else if (previousScrollPercentage < 75 && percentage >= 75 && percentage < 90 && percentage75Fired === false) {
            percentage75Fired = true;
            trackEventGlobalAndLocal('scroll', '75%scrolled', $(document).attr('title'), true);
            //GA4
            trackEventGlobalAndLocalGA4({ 'ga4_name': 'custom_scroll', 'ga4_scroll_depth': '75%' });
        } else if (previousScrollPercentage < 90 && percentage >= 90 && percentage100Fired === false) {
            percentage100Fired = true;
            trackEventGlobalAndLocal('scroll', '100%scrolled', $(document).attr('title'), true);
            //GA4
            trackEventGlobalAndLocalGA4({ 'ga4_name': 'custom_scroll', 'ga4_scroll_depth': '100%' });
        }
        previousScrollPercentage = percentage;

        //track impressions
        //$('.track-GA4-event-view').each(function (i, e) {
        //    if ($(this).hasClass('impression-sent')) {
        //        //GA impression already sent
        //    } else {
        //        var inviewport = $(this).isInViewport();
        //        if (inviewport) {
        //            SendImpressionToGA($(this), i);
        //            log('in viewport: ' + $(this)[0].dataset.analyticsId + ' | ' + $(this)[0].dataset.productName);
        //            $(this).addClass('impression-sent');
        //        }
        //    }
        //});
    }
}

$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

function SendImpressionToGA(el, position) {
    trackEventGlobalAndLocalGA4(el.data());
    //var preferences = $.gdprcookie.preference();
    //if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
    //    var impression = createProduct(el.data());
    //    impression['list'] = el.data('analyticsImpression');
    //    impression['position'] = position + 1;
    //    impression['list_name'] = el.data('analyticsImpression');
    //    impression['list_position'] = position + 1;
    //    impression['price'] = el.data('analyticsPrice');
    //    impression['id'] = el.data('analyticsId');

    //    try {
    //        window["AnalyticsBuilder"].create(window.inTestMode ? { debug: true } : {})
    //            .addImpression(impression)
    //            .send();
    //        log("*GDPR-statistics + GDPR-marketing: data-analytics-impression");
    //    } catch (e) {
    //        //speciaal voor Brave browser
    //        log("*GDPR-statistics + GDPR-marketing BLOCKED: data-analytics-impression");
    //    }

    //}
}

function HandleCompareSectionExpandedAttribute() {

    var hasCollapsedClass = $('.compare-bar-wrapper').hasClass("collapsed");
    if (hasCollapsedClass) {
        $("#compare-header-button").attr("aria-expanded", "false");
        $(".compare-item button.btn-compare").attr("aria-expanded", "false");
    } else {
        $("#compare-header-button").attr("aria-expanded", "true");
        $(".compare-item button.btn-compare").attr("aria-expanded", "true");
    }
}


function HandleTestModeSwitch() {
    if (window.location.href.indexOf('localhost') > 0) {
        if (window.location.href.indexOf('mode=test') > 0) {
            window.location = window.location.href.replace("test", "normal")
        } else if (window.location.href.indexOf('mode=normal') > 0) {
            window.location = window.location.href.replace("normal", "test")
        } else {
            window.location = window.location.href + (window.location.href.indexOf('?') > 0 ? '&' : '?') + 'mode=normal'
        }
    } else {
        window.location = window.location.href.replace("test.", "www.")
    }
};

function SwipeGallery(selectorBase, selectorGalleryItem) {
    var imageElement =
        '<div class="giant-swipe-tile" data-count="{count}">' +
        '<img src="{src}" alt="Gallery Image" />' +
        '<div class="caption">' +
        '<p>{caption}</p>' +
        '</div>' +
        '</div>';
    var swipePanelElement =
        '<div class="gallery-backdrop"><div class="swipe-panel"></div></div>';

    var panelStyle = {
        backdrop: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            transition: 'background-color ease-out 200ms',
            zIndex: 9995
        },
        swipePanel: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 10000
        }
    };

    var tileStyles = {
        wrapper: {
            position: 'fixed',
            width: 'calc(100% - 20px)',
            border: '1px solid rgba(255, 255, 255, 0.15)',
            borderRadius: 3,
            left: 10,
            zIndex: 9999
        },
        image: {
            width: '100%',
        },
        caption: {
            width: '100%',
            padding: 5,
            color: '#fff',
            backgroundColor: 'rgba(0,0,0,0.7)'
        }
    };

    var indexStyles = {
        wrapper: {
            position: 'fixed',
            bottom: '5px',
            opacity: 0.6,
            padding: 0,
            margin: 0
        },
        pointer: {
            display: 'inline-block',
            width: 10,
            height: 10,
            backgroundColor: '#fff',
            borderRadius: '50%',
            margin: 5
        }
    };

    try {
        if (($ || false) === false)
            return;
    } catch (ex) {
        return;
    }

    var baseElement = $(selectorBase),
        galleryOpen = false;

    if (baseElement.length === 0)
        return;

    var images = $(selectorBase + ' ' + selectorGalleryItem),
        backdrop = $(swipePanelElement),
        imageTiles = [],
        index = $('<ul></ul>'),
        pointers = [];
    var swipePanel = backdrop.children();
    var curPosition = 0;

    backdrop.hide().css(panelStyle.backdrop)
        .click(toggleGall);
    swipePanel.css(panelStyle.swipePanel);
    index.css(indexStyles.wrapper);

    baseElement.append(backdrop);

    var windowHeight = $(window).height();

    var count = images.length;
    images.each(function (i, e) {
        $(this).click(toggleGall);

        var el = $(
            imageElement
                .replace('{count}', i)
                .replace('{src}', $(this).data('url'))
                .replace('{caption}', $(this).data('caption'))
        );
        el.hide().css(tileStyles.wrapper).children('img')
            .css(tileStyles.image);
        el.children('.caption').css(tileStyles.caption);

        var pointer = $('<li></li>');
        index.append(pointer.css(indexStyles.pointer));
        backdrop.append(el);

        if (!--count) {
            imageTiles = backdrop.children().not('.swipe-panel,ul');
            pointers = index.children();
            backdrop.append(index);
        }
    });

    function doSwipe(e) {
        if (!galleryOpen)
            return;

        switch (e) {
            case 'right':
                if (curPosition === 0)
                    return;
                curPosition--;
                break;
            case 'left':
                if (curPosition === (images.length - 1))
                    return;
                curPosition++;
                break;
        }

        calcImageStepping(true);
    }

    swipedetect(swipePanel[0], doSwipe);

    $(document).keydown(function (e) {
        switch (e.which) {
            case 37:
                doSwipe('right');
                break;
            case 39:
                doSwipe('left');
                break;
            default:
                return;
        }
        e.preventDefault();
    });

    function toggleGall(e) {
        if (galleryOpen) {
            $('body').css({ overflow: 'auto' });
            backdrop.hide().css({ backgroundColor: 'transparent' });
            imageTiles.hide();
            enableScroll();
        } else {
            curPosition = parseInt($(e.target).parent().data('count'));
            $('body').css({ overflow: 'hidden' });
            backdrop.show().css({ backgroundColor: 'rgba(0,0,0,0.4)' });
            calcImageStepping();
            index.css({ left: 'calc(50% - ' + index.width() / 2 + 'px)' });
            disableScroll();
        }
        galleryOpen = !galleryOpen;
    }

    function calcImageStepping(transition) {
        imageTiles.show().each(function (i) {
            var el = $(this);

            if (!transition) {
                el.css({ transition: 'none' });
            }

            var curCount = parseInt(el.data('count'));
            if (curCount < curPosition)
                el.css({ transform: 'translateX(' + -($(window).width() - 10) + 'px)' });
            else if (curCount > curPosition)
                el.css({ transform: 'translateX(' + ($(window).width() - 10) + 'px)' });
            else if (curCount == curPosition)
                el.css({ transform: 'translateX(0px)' });

            if (!transition) {
                el[0].offsetHeight;
                el.css({ transition: 'transform ease-out 200ms' });
            }

            var height = el.children('img').height();
            var capHeight = el.children('.caption').height();

            el.css({
                top: (windowHeight - height - capHeight) / 2
            });
        });
        pointers.css({ backgroundColor: indexStyles.pointer.backgroundColor });
        $(pointers[curPosition]).css({ backgroundColor: '#007aff' });
    }
}

function loadExternalScript(url, callback) {
    try {
        jQuery.ajax({
            url: url,
            dataType: 'script',
            async: true,
            success: callback
        });
    } catch (e) {
        var script = document.createElement('script');
        script.src = url;
        if (document.body != null) {
            document.body.appendChild(script);
        } else if (document.head != null) {
            document.head.appendChild(script);
        }
    }
}

function LazyLoadImage(el) {
    if (($(el).attr('src') == undefined || $(el).attr('src').length == 0 || $(el).attr('src').indexOf('Spacer') > -1 || $(el).attr('src').indexOf('placeholder') > -1 || $(el).is("source")) && $(el).attr('data-src')) {
        //log('Image loaded: ' + $(el).attr('data-src'));
        if (el.nodeName == 'DIV' || el.nodeName == 'PICTURE' || el.nodeName == 'LI') {
            $(el).css('background-image', 'url(' + $(el).attr('data-src') + ')')
        } else if ($(el).is("source")) {
            $(el).attr('srcset', $(el).attr('data-src'));
        } else {
            el.src = $(el).attr('data-src');
        }
    }
}

//function createModalObserver(t) {
//    // create an observer instance
//    var observer = new MutationObserver(function (mutations) {
//        if ($(mutations[0].target).is(':visible')) {
//            openDialog(mutations[0].target);
//        } else {
//            closeDialog(mutations[0].target);
//        }
//    });
//    // configuration of the observer
//    var config = {
//        attributes: true
//    };

//    // pass in the target node, as well as the observer options
//    observer.observe(t, config);
//}

var nonModalNodes;

//A360: set tab index to -1 for all other items outside modal window
function openDialog(el) {
    try {
        if (el != undefined) {
            var modalNodes = Array.from(el.querySelectorAll('*'));

            // by only finding elements that do not have tabindex="-1" we ensure we don't
            // corrupt the previous state of the element if a modal was already open
            nonModalNodes = document.querySelectorAll('body *:not(.dialog):not([tabindex="-1"])');

            for (var i = 0; i < nonModalNodes.length; i++) {
                var node = nonModalNodes[i];

                if (!modalNodes.includes(node)) {

                    // save the previous tabindex state so we can restore it on close
                    node._prevTabindex = node.getAttribute('tabindex');
                    node.setAttribute('tabindex', -1);

                    // tabindex=-1 does not prevent the mouse from focusing the node (which
                    // would show a focus outline around the element). prevent this by disabling
                    // outline styles while the modal is open
                    // @see https://www.sitepoint.com/when-do-elements-take-the-focus/
                    node.style.outline = 'none';
                }
            }
        }
    } catch (e) {

    }
}

function closeDialog() {

    // close the modal and restore tabindex
    //if (this.type === 'modal') {
    if (nonModalNodes != undefined) {
        document.body.style.overflow = null;

        // restore or remove tabindex from nodes
        for (var i = 0; i < nonModalNodes.length; i++) {
            var node = nonModalNodes[i];
            if (node._prevTabindex) {
                node.setAttribute('tabindex', node._prevTabindex);
                node._prevTabindex = null;
            }
            else {
                node.removeAttribute('tabindex');
            }
            node.style.outline = null;
        }
    }
    //}
}


function ShowScreenReaderOnlyDialog(section, action) {

    // remove other dialogs
    $(".sr-dialog").remove();

    // define text
    var text = "Item has been added to the Compare Products region";

    // create html
    var dialogHtml = "<span role='alert' class='sr-dialog sr-only'><p>" + text + "</p><button onclick='javascript:CloseScreenReaderDialog();' aria-label='close'><i class='glyphicon glyphicon-remove'></i></button></span>";

    // append
    $("main#main").append(dialogHtml);

}

function CloseScreenReaderDialog() {
    $(".sr-dialog").remove();
}

function hideCookieBackdrop() {
    // set backdrop cookie
    var d = new Date();
    d.setTime(d.getTime() + (732 * 24 * 60 * 60 * 1000));
    document.cookie = "cookie-hidebackdrop=1;expires=" + d.toUTCString(); + ";path=/" + culture + ";";
    // remove class
    $("div.gdprcookie-backdrop").removeClass("backdrop-visible");
}

function IsInTestMode() {
    var result = false;
    try {
        result = (window.inTestMode != undefined && window.inTestMode ? true : false);
    } catch (e) {
        //result stays false
    }
    return result;
}




function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}

function ValidateEmail(inputText, callbackError) {
    var mailformat = /.+\@.+\..+/;
    if (inputText.match(mailformat)) {
        return true;
    }
    else {
        if (callbackError) {
            callbackError();
        } else {
            alert("You have entered an invalid email address!");
        }
        return false;
    }
}


function setItemCount() {
    var tileCount = $("#productsContainer .mix:visible").length;
    //var itemLabel = tileCount == 1 ? labelItem : labelItems;
    $("#number-of-results #countActiveRows").text(tileCount);
}

//function ShowConsentYoutubeLayer() {
//    var preferences = $.gdprcookie.preference();
//    if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
//        //consent given. Set src
//        $(this).attr('src', $(this).attr('data-src'));
//    }
//}


function activateProductSubCategory(subCategoryId) {

    // check if its already open - then close
    var isSameAsOpened = $(".categories #" + subCategoryId).is(":visible");

    // hide others
    $(".categories .subcategory").hide();
    $("a.toggle-product-subcategory").removeClass("active");

    // on mobile its a toggle on desktop is ain't
    if (isSameAsOpened == false || isMobile() == false) {
        // open
        $(".categories #" + subCategoryId).show();

        // set link to active
        $("ul li a[data-id='" + subCategoryId + "']").addClass("active");
    }
   
}


function CloseHighlight(myId) {

    $("body").removeClass("has-active-highlight");
    $(".highlight-link").removeClass("highlight-link-active");
    $(".highlight-info").removeClass("highlight-info-active");
    $(".highlight-link").attr("aria-expanded", "false");
    // remove x 
    $("button#highlight-link-" + myId).text(myId);

}


String.prototype.format = function () {
    // store arguments in an array
    var args = arguments;
    // use replace to iterate over the string
    // select the match and check if the related argument is present
    // if yes, replace the match with the argument
    return this.replace(/{([0-9]+)}/g, function (match, index) {
        // check if the argument is present
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};

function waitUntilElementLoad(elem, maxWait, successCallback, stopWaitingCallback) {
    try {
        if (eval(elem) != undefined) {
            if (successCallback) { successCallback(); }
            return true;
        }
    } catch (e) {
        if (stopWaitingCallback) {
            if (stopWaitingCallback() == true) {
                return true;
            }
        }
    }
    setTimeout(function () { waitUntilElementLoad(elem, maxWait, successCallback, stopWaitingCallback) }, maxWait);
    return false;
}

function log(message) {
    var debug = readCookie('consoleLog');

    if ((window.inTestMode != undefined && window.inTestMode == true)
        || (window.isDeveloperMachine != undefined && window.isDeveloperMachine == true)
        || (debug != null && debug.toLowerCase() == 'true')) {
        console.log(message);
    }
}

